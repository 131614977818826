// Единое место хранение всех EnpPoint'ов для взаимодействия с внешним API (Сервером)

export const URL = {
    // BASE: 'https://api-preprod.nibbleinvest.ru/client-interface',
    BASE: 'https://backend.ca-mir.ru',
};

export const USER = {
    // TODO Set api routes
    SEND_FEEDBACK: '/api/boostrs',
    SEND_CALL_REQUEST: '/?',
    GET_ID_AGREEMENT: '/?',
    // GET_PAYMENT: '/?',
    GET_PAYMENT: 'https://jsonplaceholder.typicode.com/todos/1',
};

export const SISTEM = {
    GET_STRATEGY: '/portfolio/getProductList',
};

// // Функция подготовки данных для передачи их на сервер, и полученного отвате для размещения их в State
// import { api } from 'api/axios/api';
// import { USER } from 'api/constants/endPoints';
// import { SendFeedbackParams } from './sendFeedbackTypes';

// async function sendFeedbackFetch(params: SendFeedbackParams) {
//     const response = await api.post(USER.SEND_FEEDBACK, params);

//     return response;
// }

// export { sendFeedbackFetch };

import { USER } from 'api/constants/endPoints';
import { SendFeedbackParams } from './sendFeedbackTypes';
import { api } from 'api/axios/api';

async function sendFeedbackFetch(params: SendFeedbackParams) {
    const response = await api.post(USER.SEND_FEEDBACK, params);

    return response;
}

export { sendFeedbackFetch };

// Слайс управления всплывающими окнами
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { position } from 'constants/popup/position';
// import { rights } from 'constants/rights/rights';
import { IPopup } from 'types/IPopup';

const PopupStateArrayTypes = [
    'PopupFeedback',
    'PopupCallDebt',
    'PopupCallInfo',
    'PopupPaymentDebt',
    'PopupCheckDebt',
    'PopupResult',
] as const;

export type PopupStateKey = (typeof PopupStateArrayTypes)[number];

type PopupState = {
    // eslint-disable-next-line no-unused-vars
    [key in PopupStateKey]: IPopup;
};

// Тип объекта управления состоянием передаваемый в payload
interface Status {
    popup: keyof PopupState;
    active: boolean;
}

// Хранилище данных по умолчанию
const initialState: PopupState = {
    PopupFeedback: {
        isOpen: false,
        cross: true,
        position: position.center,
        closingBackground: true,
        roles: [],
    },

    PopupCallDebt: {
        isOpen: false,
        cross: true,
        position: position.center,
        closingBackground: true,
        roles: [],
    },

    PopupCallInfo: {
        isOpen: false,
        cross: true,
        position: position.center,
        closingBackground: true,
        roles: [],
    },

    PopupPaymentDebt: {
        isOpen: false,
        cross: true,
        position: position.center,
        closingBackground: true,
        roles: [],
    },
    PopupCheckDebt: {
        isOpen: false,
        cross: true,
        position: position.bottom,
        closingBackground: true,
        roles: [],
    },
    PopupResult: {
        isOpen: false,
        cross: true,
        position: position.center,
        closingBackground: true,
        roles: [],
    },
};

// Логика изменения хранилища
export const popupSlice = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        togglePopup: {
            reducer: (state, action: PayloadAction<Status>) => {
                if (action.payload.active) {
                    popupSlice.caseReducers.closeAllPopups(state, {
                        payload: undefined,
                        type: 'popup/closeAllPopups',
                    });
                }
                state[action.payload.popup].isOpen = action.payload.active;
            },
            prepare: (value: Status) => ({ payload: value }),
        },
        closeAllPopups: {
            reducer: (state, action: PayloadAction<void>) => {
                for (const popupName of PopupStateArrayTypes) {
                    if (state[popupName].isOpen == true) {
                        state[popupName].isOpen = false;
                    }
                }
            },
            prepare: () => ({ payload: undefined }),
        },
    },
});

// Деструктуризация actionCreators
export const { togglePopup, closeAllPopups } = popupSlice.actions;

export default popupSlice.reducer;

import React, { FC, Suspense } from 'react';
import { AppView } from './AppView';
import { AppMobileView } from './AppMobileView';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { LoaderStandard } from 'components/ui/loaders';
import { ScrollRestoration } from 'react-router-dom';

const App: FC = () => {
    const isMobile = useAppSelector((state) => state.appReducer.isMobile);

    return (
        <Suspense fallback={<LoaderStandard fullScreen />}>
            {isMobile ? <AppMobileView /> : <AppView />}
            <ScrollRestoration getKey={(location) => location.key} />
        </Suspense>
    );
};

export { App };

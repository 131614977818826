// Слайс управления вкладками
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITab } from 'types/ITab';

// Описание данных хранилища
interface TabsState {
    [key: string]: ITab;
}

// Тип объекта управления состоянием передаваемый в payload
interface Status {
    tab: keyof TabsState;
    id: number;
}

interface Elements {
    tab: keyof TabsState;
    elements: number[];
}

// Хранилище данных по умолчанию
const initialState: TabsState = {
    TestTab: {
        activeId: 0,
        withdrawn: [1], // Параметр убирает вкладки с данными индексами из отображаемых [1, 2]
    },
    TestTab2: {
        activeId: 0,
        withdrawn: [],
    },
    tabFaq: {
        activeId: 0,
        withdrawn: [],
    },
    PopupPaymentDebtTab: {
        activeId: 0,
        withdrawn: [],
    },
};

// Логика изменения хранилища
export const tabsSlice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        activeTab: {
            reducer: (state, action: PayloadAction<Status>) => {
                state[action.payload.tab].activeId = action.payload.id;
            },
            prepare: (value: Status) => ({ payload: value }),
        },
        withdrawnTab: {
            reducer: (state, action: PayloadAction<Elements>) => {
                console.log(action.payload);
                state[action.payload.tab].withdrawn = action.payload.elements;
            },
            prepare: (value: Elements) => ({ payload: value }),
        },
    },
});

// Деструктуризация actionCreators
export const { activeTab, withdrawnTab } = tabsSlice.actions;

export default tabsSlice.reducer;

// Функция подготовки данных для передачи их на сервер, и полученного отвате для размещения их в State
import { api } from 'api/axios/api';
import { USER } from 'api/constants/endPoints';
import { SendCallRequestParams } from './sendCallRequestTypes';

async function sendCallRequestFetch(params: SendCallRequestParams) {
    console.log(params);
    const response = await api.post(USER.SEND_CALL_REQUEST, params);
    console.log(response.data);
    if (response.data.success) {
        return true;
    } else {
        throw response.data.error;
    }
}

export { sendCallRequestFetch };

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'redux/store/store';
import reportWebVitals from './reportWebVitals';
import { Routing } from 'components/core/Routing/Routing';
import './index.scss';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <RouterProvider router={Routing} />
    </Provider>
    // </React.StrictMode>
);
reportWebVitals();

// Отображение страницы пользователю
import React from 'react';
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { createRoute } from 'hocs/routing/createRoute';
import { routes } from 'constants/routing/routes';
import { App } from '../App/App';

/**
 * createBrowserRouter был добавлен, чтобы была возможность добавить ScrollRestoration в App,
 * который позволяет делать скроллы к блокам, скролл наверх между переходами по страницам
 *
 * При старом подходе через Routes, добавить ScrollRestoration нельзя
 */
const Routing = createBrowserRouter(
    createRoutesFromElements(<Route element={<App />}>{routes.map(createRoute)}</Route>)
);

export { Routing };

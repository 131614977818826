// Конфигурация store
import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from 'redux/reducers/rootReducer';
import createSagaMiddleware from '@redux-saga/core';
import { rootSaga } from 'redux/sagas/rootSaga';

const sagaMiddleware = createSagaMiddleware();

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
    });
};

const store = setupStore();

sagaMiddleware.run(rootSaga);

export { store };

// Сага выполняющая действя при перехвате Action'а определённого типа Watcher'ом
import { call, put, delay } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { setInfo } from 'redux/reducers/slices/informerSlice/informerSlice';
import { togglePopup } from 'redux/reducers/slices/popupSlice/popupSlice';
import { SendFeedbackParams } from './sendFeedbackTypes';
import { FormFeedbackActions } from 'redux/reducers/slices/_forms/formFeedbackSlice/formFeedbackSlice';
import { sendFeedbackFetch } from './sendFeedbackFetch';
import { AxiosResponse } from 'axios';

function* sendFeedbackWorker({ payload }: PayloadAction<SendFeedbackParams>) {
    try {
        yield put(FormFeedbackActions.setIsLoading(true));

        const dataResponse: Promise<AxiosResponse> = yield call(sendFeedbackFetch, payload);

        console.log(dataResponse);

        yield put(togglePopup({ popup: 'PopupResult', active: true }));
        yield put(FormFeedbackActions.setIsValid(true));
        yield put(FormFeedbackActions.clearFeed());
    } catch (error) {
        console.log(error);
        yield put(togglePopup({ popup: 'PopupFeedback', active: false }));
        yield put(FormFeedbackActions.setIsValid(false));
        yield put(
            setInfo({
                show: true,
                status: 'error',
                text: String(error) === '24' ? '24' : '3',
                timer: 10,
            })
        );
    } finally {
        yield put(FormFeedbackActions.setIsLoading(false));
    }
}

export { sendFeedbackWorker };

// Слайс изначального с основными настройками приложения
import { createSlice } from '@reduxjs/toolkit';

// Описание данных хранилища
interface StaticDataState {
    phone: string;
    additionalPhone: string;
    phoneWhatsapp: string;
    email: string;
    workTime: string;
    address: string;
    whatsappLink: string;
}

// Хранилище данных по умолчанию
const initialState: StaticDataState = {
    phone: '+7 (771) 944-45-64',
    phoneWhatsapp: '+7 (700) 392-03-53',
    additionalPhone: '+7 (700) 392-03-53',
    // phoneWhatsapp: '+7 (771) 772-18-34',
    email: 'info@boostrfinance.kz',
    workTime: 'Пн-Вс 09:00-18:00 по г. Алматы',
    address: 'г. Алматы, мкр. «Коктем – 1», дом 50, 1 этаж, офис 99',
    whatsappLink: 'https://wa.me/77003920353',
};

// Логика изменения хранилища
export const staticDataSlice = createSlice({
    name: 'staticData',
    initialState,
    reducers: {},
});

// Деструктуризация actionCreators
export const {} = staticDataSlice.actions;

export default staticDataSlice.reducer;

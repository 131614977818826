import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { IFields } from 'types/IFields';
import { ValidatePhoneLength } from 'utils/validatePatterns/validatePhoneLength';

interface SelectData {
    value: string;
    label: string;
}

interface FormFeedbackState {
    formFeedback: {
        // feedSelect: {
        //     currentValue: IFields;
        //     data: string[];
        // };
        feedSelectRequest: IFields;
        feedFIO: IFields;
        feedPhone: IFields;
        feedMessage: IFields;
        feedAgreement: IFields;
    };
    feedSelectData: SelectData[];
    isloading: boolean;
    isValid: boolean;
}

const initialState: FormFeedbackState = {
    formFeedback: {
        feedSelectRequest: {
            value: '',
            validate: { required: { value: true, message: 'error1' } },
            params: {
                aleas: 'FIO',
                disabled: false,
                customError: '',
            },
        },
        feedFIO: {
            value: '',
            validate: {
                required: { value: true, message: 'error3' },
                pattern: {
                    value: /^[A-Za-zА-Яа-яёЁ]+(?:[-\s][A-Za-zА-Яа-яёЁ]+)*$/,
                    message: 'error2',
                },
            },
            params: {
                aleas: 'FIO',
                disabled: false,
                customError: '',
            },
        },
        feedPhone: {
            value: '',
            validate: {
                required: { value: true, message: 'error1' },
                validate: (value) => ValidatePhoneLength(value, 11, 'error2'),
            },
            params: {
                aleas: 'phone',
                disabled: false,
                mask: '_ ',
                format: '+7 (###) ###-##-##',
                customError: '',
            },
        },
        feedMessage: {
            value: '',
            validate: {
                required: { value: true, message: 'error7' },
            },
            params: {
                aleas: 'appeal',
                disabled: false,
                customError: '',
            },
        },
        feedAgreement: {
            value: '',
            validate: {
                required: { value: true, message: 'error1' },
            },
            params: {
                aleas: 'appeal',
                disabled: false,
                customError: '',
            },
        },
    },
    feedSelectData: [
        { value: 'Узнать задолженность', label: 'Узнать задолженность' },
        { value: 'Узнать номер договора', label: 'Узнать номер договора' },
        { value: 'Заявка на реструктуризацию', label: 'Заявка на реструктуризацию' },
        { value: 'Запросить справку о погашении', label: 'Запросить справку о погашении' },
        { value: 'Другое', label: 'Другое' },
    ],
    isloading: false,
    isValid: false,
};

export const formFeedbackSlice = createSlice({
    name: 'formFeed',
    initialState,
    reducers: {
        changeSelectRequest: (state, action: PayloadAction<string>) => {
            state.formFeedback.feedSelectRequest.value = action.payload;
        },
        changeFeedFIO: (state, action: PayloadAction<string>) => {
            state.formFeedback.feedFIO.value = action.payload;
        },

        changeFeedPhone: (state, action: PayloadAction<string>) => {
            state.formFeedback.feedPhone.value = action.payload;
        },
        changeFeedMessage: (state, action: PayloadAction<string>) => {
            state.formFeedback.feedMessage.value = action.payload;
        },
        changeFeedAgreement: (state, action: PayloadAction<string>) => {
            state.formFeedback.feedAgreement.value = action.payload;
        },
        clearFeed: (state, action: PayloadAction<void>) => {
            state.formFeedback.feedSelectRequest.value = '';
            state.formFeedback.feedFIO.value = '';
            state.formFeedback.feedPhone.value = '';
            state.formFeedback.feedMessage.value = '';
            state.formFeedback.feedAgreement.value = '';
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isloading = action.payload;
        },
        setIsValid: (state, action: PayloadAction<boolean>) => {
            state.isValid = action.payload;
        },
    },
});

export const FormFeedbackActions = formFeedbackSlice.actions;

export default formFeedbackSlice.reducer;

// Данная функция определяет является ли устройство пользователя мобильным
const getIsMobile = (): boolean => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
            navigator.userAgent
        )
    ) {
        return true;
    } else {
        return false;
    }
};

export { getIsMobile };

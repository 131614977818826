// Слайс списка выбора языков
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Описание данных хранилища
interface LangState {
    active: boolean;
}

// Хранилище данных по умолчанию
const initialState: LangState = {
    active: false,
};

// Логика изменения хранилища
export const langSlice = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        toggleLang: {
            reducer: (state, action: PayloadAction<boolean>) => {
                state.active = action.payload;
            },
            prepare: (value: boolean) => ({ payload: value }),
        },
    },
});

// Деструктуризация actionCreators
export const { toggleLang } = langSlice.actions;

export default langSlice.reducer;

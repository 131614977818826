// Функция подготовки данных для передачи их на сервер, и полученного отвате для размещения их в State
import { api } from 'api/axios/api';
import { USER } from 'api/constants/endPoints';
import { PaymentState } from 'redux/reducers/slices/paymentSlice/paymentSlice';

async function getPaymentFetch() {
    const response = await api.get<PaymentState>(USER.GET_PAYMENT);
    // console.log(response.data);
    if (response.data) {
        return response.data;
    } else {
        throw response.status;
    }
}

export { getPaymentFetch };

import React from 'react';
import { Outlet } from 'react-router-dom';
import './App.scss';

const AppView = () => {
    return (
        <div className="app">
            <Outlet />
        </div>
    );
};

export { AppView };

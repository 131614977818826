import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import './App.scss';

const AppMobileView: FC = () => {
    return (
        <div className="app-mobile">
            <Outlet />
        </div>
    );
};

export { AppMobileView };

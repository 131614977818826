// Слайс изначального с основными настройками приложения
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTheme } from 'utils/theme/getThem';
import { getLanguage } from 'utils/language/getLanguage';
import { getIsMobile } from 'utils/devices/getIsMobile';
import type { ITheme } from 'types/ITheme';

// Описание данных хранилища
interface AppState {
    theme: ITheme;
    language: string;
    isMobile: boolean;
    loading: boolean;
}

// Хранилище данных по умолчанию
const initialState: AppState = {
    theme: getTheme(),
    language: getLanguage(),
    isMobile: getIsMobile(),
    loading: false,
};

// Логика изменения хранилища
export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setTheme: {
            reducer: (state, action: PayloadAction<ITheme>) => {
                localStorage.setItem('theme', action.payload);
                state.theme = action.payload;
            },
            prepare: (value: ITheme) => ({ payload: value || 'dark' }),
        },

        setLanguage: {
            reducer: (state, action: PayloadAction<string>) => {
                document.documentElement.lang = action.payload;
                localStorage.setItem('language', action.payload);
                state.language = action.payload;
            },
            prepare: (value: string) => ({ payload: value }),
        },
        setIsMobile: {
            reducer: (state, action: PayloadAction<boolean>) => {
                state.isMobile = action.payload;
            },
            prepare: (value: boolean) => ({ payload: value }),
        },
        toggleLoading: {
            reducer: (state, action: PayloadAction<boolean>) => {
                state.loading = action.payload;
            },
            prepare: (value: boolean) => ({ payload: value }),
        },
    },
});

// Деструктуризация actionCreators
export const { setTheme, setLanguage, setIsMobile, toggleLoading } = appSlice.actions;

export default appSlice.reducer;

// Слайс управления главным меню
import { createSlice } from '@reduxjs/toolkit';
import type { IMenuItem } from 'types/IMenuItem';
import { rights } from 'constants/rights/rights';

// Описание данных хранилища
interface MenuState {
    items: IMenuItem[];
}

// Хранилище данных по умолчанию
const initialState: MenuState = {
    items: [
        {
            name: 'Документы Бустэр',
            alias: 'documentsBoostr',
            link: '#static-link',
            roles: [rights.user, rights.client],
        },
        {
            name: 'Документы Бустэр',
            alias: 'documentsBoostr_1',
            link: '#static-link',
            roles: [rights.user, rights.client],
        },
    ],
};

// Логика изменения хранилища
export const footerMenuSlice = createSlice({
    name: 'footerMenu',
    initialState,
    reducers: {},
});

// Деструктуризация actionCreators
export const {} = footerMenuSlice.actions;

export default footerMenuSlice.reducer;

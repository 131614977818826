import { takeLatest } from 'redux-saga/effects';
import {
    sendCallDebtRequestWorker,
    sendCallInfoRequestWorker,
} from 'redux/sagas/workers/sendCallRequestWorker/sendCallRequestWorker';
import { SEND_CALL_DEBT_REQUEST, SEND_CALL_INFO_REQUEST } from './sendCallRequestActionTypes';

export function* sendCallDebtRequestWatcher() {
    yield takeLatest(SEND_CALL_DEBT_REQUEST, sendCallDebtRequestWorker);
}

export function* sendCallInfoRequestWatcher() {
    yield takeLatest(SEND_CALL_INFO_REQUEST, sendCallInfoRequestWorker);
}

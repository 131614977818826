import { SendCallRequestParams } from './sendCallRequestTypes';
import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { setInfo } from 'redux/reducers/slices/informerSlice/informerSlice';
import { clearForm, setIsValid } from 'redux/reducers/slices/_forms/formCallSlice/formCallSlice';
import { sendCallRequestFetch } from './sendCallRequestFetch';
import { togglePopup } from 'redux/reducers/slices/popupSlice/popupSlice';

function* sendCallDebtRequestWorker({ payload }: PayloadAction<SendCallRequestParams>) {
    try {
        const data: boolean = yield call(sendCallRequestFetch, payload);
        console.log(data);
        yield put(setIsValid(true));
    } catch (error) {
        console.log(error);
        yield put(togglePopup({ popup: 'PopupCallDebt', active: false }));
        yield put(setIsValid(false));
        yield put(
            setInfo({
                show: true,
                status: 'error',
                text: String(error) === '24' ? '24' : '3',
                timer: 10,
            })
        );
    } finally {
        yield put(clearForm());
    }
}

function* sendCallInfoRequestWorker({ payload }: PayloadAction<SendCallRequestParams>) {
    try {
        const data: boolean = yield call(sendCallRequestFetch, payload);
        console.log(data);
        yield put(setIsValid(true));
    } catch (error) {
        console.log(error);
        yield put(togglePopup({ popup: 'PopupCallInfo', active: false }));
        yield put(setIsValid(false));
        yield put(
            setInfo({
                show: true,
                status: 'error',
                text: String(error) === '24' ? '24' : '3',
                timer: 10,
            })
        );
    } finally {
        yield put(clearForm());
    }
}

export { sendCallDebtRequestWorker, sendCallInfoRequestWorker };

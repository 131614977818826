import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFields } from 'types/IFields';
import { ValidatePhoneLength } from 'utils/validatePatterns/validatePhoneLength';

interface FormCallState {
    formCall: {
        callFIO: IFields;
        callPhone: IFields;
    };
    loading: boolean;
    isValid: boolean;
}

const initialState: FormCallState = {
    formCall: {
        callFIO: {
            value: '',
            validate: {
                required: { value: true, message: 'error3' },
                pattern: {
                    value: /^[A-Za-zА-Яа-яёЁ]+(?:[-\s][A-Za-zА-Яа-яёЁ]+)*$/,
                    message: 'error2',
                },
            },
            params: {
                aleas: 'FIO',
                disabled: false,
                customError: '',
            },
        },
        callPhone: {
            value: '',
            validate: {
                required: { value: true, message: 'error1' },
                validate: (value) => ValidatePhoneLength(value, 11, 'error2'),
            },
            params: {
                aleas: 'phone',
                disabled: false,
                mask: '_ ',
                format: '+7 (###) ###-##-##',
                customError: '',
            },
        },
    },
    loading: false,
    isValid: false,
};

export const formCallSlice = createSlice({
    name: 'formCall',
    initialState,
    reducers: {
        changeCallName: {
            reducer: (state, action: PayloadAction<string>) => {
                state.formCall.callFIO.value = action.payload;
            },
            prepare: (value: string) => ({ payload: value }),
        },
        changeCallPhone: {
            reducer: (state, action: PayloadAction<string>) => {
                state.formCall.callPhone.value = action.payload;
            },
            prepare: (value: string) => ({ payload: value }),
        },
        clearForm: {
            reducer: (state, action: PayloadAction<void>) => {
                state.formCall.callFIO.value = '';
                state.formCall.callPhone.value = '';
            },
            prepare: () => ({ payload: undefined }),
        },
        setIsValid: {
            reducer: (state, action: PayloadAction<boolean>) => {
                state.isValid = action.payload;
            },
            prepare: (value: boolean) => ({ payload: value }),
        },
    },
});

// Деструктуризация actionCreators
export const { changeCallName, changeCallPhone, clearForm, setIsValid } = formCallSlice.actions;

export default formCallSlice.reducer;

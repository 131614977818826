// @ts-nocheck
// Сага выполняющая действя при перехвате Action'а определённого типа Watcher'ом
import { call, put, delay, race, take } from 'redux-saga/effects';
import { setInfo } from 'redux/reducers/slices/informerSlice/informerSlice';
import {
    clearPaymentData,
    PaymentState,
    setPaymentData,
    setPaymentStatus,
} from 'redux/reducers/slices/paymentSlice/paymentSlice';
import { togglePopup } from 'redux/reducers/slices/popupSlice/popupSlice';
import { CANCEL_GET_PAYMENT_DEBT_POLLING } from 'redux/sagas/watchers/getPaymentWatcher/getPaymentActionTypes';
import { getPaymentFetch } from './getPaymentFetch';

function* getPaymentWorker() {
    try {
        yield put(clearPaymentData());

        const data: PaymentState = yield call(getPaymentFetch);
        console.log(data);
        yield put(
            setPaymentData({
                payment: data.payment,
                idPayment: data.idPayment,
                status: data.status,
            })
        );

        yield call(getPaymentStatusWatchWorker);
    } catch (error) {
        console.log(error);
        yield put(
            setInfo({
                show: true,
                status: 'error',
                text: String(error) === '24' ? '24' : '3',
                timer: 10,
            })
        );
    }
}

const POLLING_DELAY = 2500;

export function* getPaymentStatusWatchWorker() {
    // Race starts two concurrent effects. We start our polling effect 'task'. As soon
    // as the take effect 'cancelled' is triggered, the 'task' will be cancelled.
    yield race({
        // Start the polling worker
        task: call(getPaymentStatusPollingWorker),
        // Start a take effect waiting for the cancel action.
        cancel: take(CANCEL_GET_PAYMENT_DEBT_POLLING),
    });
}

export function* getPaymentStatusPollingWorker() {
    // Run indefinitely
    while (true) {
        try {
            const data: PaymentState = yield call(getPaymentFetch);
            console.log('longPolling', data);
            yield put(
                setPaymentData({
                    payment: data.payment,
                    idPayment: data.idPayment,
                    status: data.status,
                })
            );

            yield delay(POLLING_DELAY);

            //
            data.status = 'success';
            yield put(
                setPaymentData({
                    payment: '23123weq',
                    idPayment: 'qweqwe',
                    status: 'success',
                })
            );
            //
            if (data.status === 'success') {
                yield put({ type: CANCEL_GET_PAYMENT_DEBT_POLLING });
            }
            if (data.status === 'error') {
                yield put(togglePopup({ popup: 'PopupPaymentDebt', active: false }));
                yield put(
                    setInfo({
                        show: true,
                        status: 'error',
                        text: '3',
                        timer: 10,
                    })
                );
                yield put({ type: CANCEL_GET_PAYMENT_DEBT_POLLING });
            }
        } catch (error) {
            // Catch any unexpected errors and cancel polling if something goes wrong.
            console.error(error);
            yield put(togglePopup({ popup: 'PopupPaymentDebt', active: false }));
            yield put(setPaymentStatus('error'));
            yield put(
                setInfo({
                    show: true,
                    status: 'error',
                    text: String(error) === '24' ? '24' : '3',
                    timer: 10,
                })
            );
            yield put({ type: CANCEL_GET_PAYMENT_DEBT_POLLING });
        }
    }
}

export { getPaymentWorker };

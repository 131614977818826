// Получение локализации приложения
import { LOCALE } from 'constants/lang/locale';

const getLanguage = () => {
    // Проверяем localStorage
    type leng = string | null;
    let language: leng = localStorage.getItem('language');
    if (!language) {
        // Проверяем локализацию ОС пользователя
        language = window.navigator && window.navigator.language;
    }
    language = LOCALE.includes(language) ? language : 'ru';

    // Переключаем язык у HTML документа
    language = String(language).slice(0, 2).toLowerCase();
    document.documentElement.lang = language;

    return language;
};

export { getLanguage };

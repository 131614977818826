// Слайс управления главным меню
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IMenuItem } from 'types/IMenuItem';
import { rights } from 'constants/rights/rights';

// Описание данных хранилища
interface MenuState {
    items: IMenuItem[];
    showSelectLang: boolean;
    showThemeToggle: boolean;
    showProfileMenu: boolean;
    stateProfileMenu: boolean;
    stateMobileMenu: boolean;
    isBurgerMenu: boolean;
}

// Хранилище данных по умолчанию
const initialState: MenuState = {
    showSelectLang: false,
    showThemeToggle: false,
    showProfileMenu: false,
    stateProfileMenu: false,
    stateMobileMenu: false,
    isBurgerMenu: true,
    items: [
        {
            name: 'Способы оплаты',
            alias: 'payment',
            link: '/#payment',
            roles: [rights.user, rights.client],
            // anchor: true,
        },
        {
            name: 'Вопросы и ответы',
            alias: 'faq',
            link: '/#faq',
            roles: [rights.user, rights.client],
            // anchor: true,
        },
        {
            name: 'О компании',
            alias: 'about',
            link: '/#about',
            roles: [rights.user, rights.client],
            // anchor: true,
        },
    ],
};

// Логика изменения хранилища
export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        toggleMobileMenu: {
            reducer: (state, action: PayloadAction<boolean>) => {
                state.stateMobileMenu = action.payload;
            },
            prepare: (value: boolean) => ({ payload: value }),
        },
        toggleSubMenu: {
            reducer: (state, action: PayloadAction<string>) => {
                state.items.forEach((item) => {
                    item.alias === action.payload
                        ? (item.stateChilds = !item.stateChilds)
                        : (item.stateChilds = false);
                });
            },
            prepare: (value: string) => ({ payload: value }),
        },
        toggleProfileMenu: {
            reducer: (state, action: PayloadAction<boolean>) => {
                state.stateProfileMenu = action.payload;
            },
            prepare: (value: boolean) => ({ payload: value }),
        },
    },
});

// Деструктуризация actionCreators
export const { toggleMobileMenu, toggleSubMenu, toggleProfileMenu } = menuSlice.actions;

export default menuSlice.reducer;

// Сага выполняющая действя при перехвате Action'а определённого типа Watcher'ом
import { put } from 'redux-saga/effects';
// import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { setInfo } from 'redux/reducers/slices/informerSlice/informerSlice';
import { togglePopup } from 'redux/reducers/slices/popupSlice/popupSlice';
import { GetIdAgreementFetchParams } from './getIdAgreementTypes';
// import { getIdAgreementFetch } from './getIdAgreementFetch';
import {
    clearForm,
    setIdAgreement,
    setIsOpen,
    setIsValid,
} from 'redux/reducers/slices/_forms/formGetIdAgreement/formGetIdAgreementSlice';

function* getIdAgreementWorker({ payload }: PayloadAction<GetIdAgreementFetchParams>) {
    try {
        // const data: boolean = yield call(getIdAgreementFetch, payload);
        // console.log(data);
        yield put(setIsValid(true));
        yield put(setIdAgreement('82934'));
    } catch (error) {
        console.log(error);
        yield put(togglePopup({ popup: 'PopupPaymentDebt', active: false }));
        yield put(setIsValid(false));
        yield put(
            setInfo({
                show: true,
                status: 'error',
                text: String(error) === '24' ? '24' : '3',
                timer: 10,
            })
        );
    } finally {
        yield put(clearForm());
        yield put(setIsOpen(false));
    }
}

export { getIdAgreementWorker };

// Конфигурация подключения axios к внешнему API
import { apiHeaders } from 'api/constants/apiHeaderType';
import { URL } from 'api/constants/endPoints';

export const CONFIG = {
    STANDARD: {
        baseURL: URL.BASE,
        // headers: apiHeaders.JSON,
        withCredentials: false,
    },
};

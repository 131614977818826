import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dateBirthPattern } from 'constants/validatePatterns/dateBirthPattern';
import { IFields } from 'types/IFields';

interface FormGetIdAgreementState {
    formGetIdAgreement: {
        agreementFIO: IFields;
        agreementDateBirth: IFields;
        agreementCreditor: IFields;
    };
    idAgreement: string;
    isOpen: boolean;
    loading: boolean;
    isValid: boolean;
}

const initialState: FormGetIdAgreementState = {
    formGetIdAgreement: {
        agreementFIO: {
            value: '',
            validate: {
                required: { value: true, message: 'error3' },
                pattern: {
                    value: /^[A-Za-zА-Яа-яёЁ]+(?:[-\s][A-Za-zА-Яа-яёЁ]+)*$/,
                    message: 'error2',
                },
            },
            params: {
                aleas: 'FIO',
                disabled: false,
                customError: '',
            },
        },
        agreementDateBirth: {
            value: '',
            validate: {
                required: { value: true, message: 'error3' },
                pattern: {
                    value: dateBirthPattern,
                    message: 'error4',
                },
            },
            params: {
                aleas: 'dateBirth',
                disabled: false,
                mask: '_ ',
                format: '##.##.####',
                customError: '',
            },
        },
        agreementCreditor: {
            value: '',
            validate: {
                required: { value: true, message: 'error8' },
            },
            params: {
                aleas: 'creditor',
                disabled: false,
                customError: '',
            },
        },
    },
    idAgreement: '',
    loading: false,
    isValid: false,
    isOpen: false,
};

export const formGetIdAgreementSlice = createSlice({
    name: 'formGetIdAgreement',
    initialState,
    reducers: {
        changeAgreementFIO: {
            reducer: (state, action: PayloadAction<string>) => {
                state.formGetIdAgreement.agreementFIO.value = action.payload;
            },
            prepare: (value: string) => ({ payload: value }),
        },
        changeAgreementDateBirth: {
            reducer: (state, action: PayloadAction<string>) => {
                state.formGetIdAgreement.agreementDateBirth.value = action.payload;
            },
            prepare: (value: string) => ({ payload: value }),
        },
        changeAgreementCreditor: {
            reducer: (state, action: PayloadAction<string>) => {
                state.formGetIdAgreement.agreementCreditor.value = action.payload;
            },
            prepare: (value: string) => ({ payload: value }),
        },
        clearForm: {
            reducer: (state, action: PayloadAction<void>) => {
                state.formGetIdAgreement.agreementFIO.value = '';
                state.formGetIdAgreement.agreementDateBirth.value = '';
                state.formGetIdAgreement.agreementCreditor.value = '';
            },
            prepare: () => ({ payload: undefined }),
        },
        setIdAgreement: {
            reducer: (state, action: PayloadAction<string>) => {
                state.idAgreement = action.payload;
            },
            prepare: (value: string) => ({ payload: value }),
        },
        setIsValid: {
            reducer: (state, action: PayloadAction<boolean>) => {
                state.isValid = action.payload;
            },
            prepare: (value: boolean) => ({ payload: value }),
        },
        setIsOpen: {
            reducer: (state, action: PayloadAction<boolean>) => {
                state.isOpen = action.payload;
            },
            prepare: (value: boolean) => ({ payload: value }),
        },
    },
});

export const {
    changeAgreementFIO,
    changeAgreementDateBirth,
    changeAgreementCreditor,
    clearForm,
    setIdAgreement,
    setIsValid,
    setIsOpen,
} = formGetIdAgreementSlice.actions;

export default formGetIdAgreementSlice.reducer;

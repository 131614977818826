/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PaymentState {
    payment?: string;
    idPayment?: string;
    status?: 'isLoading' | 'success' | 'error';
}

const initialState: PaymentState = {
    payment: undefined,
    idPayment: undefined,
    status: undefined,
};

export const paymentSlice = createSlice({
    name: 'paymentDebt',
    initialState,
    reducers: {
        setPaymentData: {
            reducer: (state, action: PayloadAction<PaymentState>) => {
                state.payment = action.payload.payment;
                state.idPayment = action.payload.idPayment;
                state.status = action.payload.status;
            },
            prepare: (value: PaymentState) => ({ payload: value }),
        },
        setPaymentStatus: {
            reducer: (
                state,
                action: PayloadAction<undefined | 'isLoading' | 'success' | 'error'>
            ) => {
                state.status = action.payload;
            },
            prepare: (value: undefined | 'isLoading' | 'success' | 'error') => ({
                payload: value,
            }),
        },
        clearPaymentData: {
            reducer: (state, action: PayloadAction<undefined>) => {
                state.payment = undefined;
                state.idPayment = undefined;
                state.status = undefined;
            },
            prepare: () => ({ payload: undefined }),
        },
    },
});

// Деструктуризация actionCreators
export const { setPaymentData, setPaymentStatus, clearPaymentData } = paymentSlice.actions;

export default paymentSlice.reducer;

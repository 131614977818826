// Создает дерево навигации
import React, { FC, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { LoaderStandard } from 'components/ui/loaders';
import type { IRouting } from 'types/IRouting';

const createRoute: FC<IRouting> = ({
    element,
    children,
    roles,
    path,
    header,
    sideBar,
    footer,
    mainContainer,
}) => {
    const Component = element;
    return (
        <Route
            key={path}
            path={path}
            element={
                <Suspense fallback={<LoaderStandard fullScreen />}>
                    <Component
                        Header={header && header}
                        SideBar={sideBar && sideBar}
                        Footer={footer && footer}
                        mainContainer={mainContainer && mainContainer}
                    />
                </Suspense>
            }
        >
            {children && children.map(createRoute)}
        </Route>
    );
};

export { createRoute };

import { all, call, spawn } from 'redux-saga/effects';
import * as watchers from './watchers/watchersList';

export function* rootSaga() {
    // Список всех саг (watchers)
    const sagas = [...Object.values(watchers)];

    // Подниматель саг
    const retrySagas = sagas.map((saga) => {
        return spawn(function* () {
            while (true) {
                try {
                    yield call(saga);
                    break;
                } catch (error) {
                    console.log('Saga', error);
                }
            }
        });
    });

    yield all(retrySagas);
}

// Структура постраничной маршрутизации приложения
import { lazy } from 'react';
import { IRouting } from 'types/IRouting';
// import { rights } from 'constants/rights/rights';

const routes: IRouting[] = [
    {
        path: '/',
        roles: [],
        header: lazy(() => import('components/template/headers/HeaderHome/HeaderHome')),
        element: lazy(
            () => import('components/template/layouts/NotificationLayout/NotificationLayout')
        ),
        footer: lazy(() => import('components/template/footers/FooterStandard/FooterStandard')),
        // mainContainer: 'container',
        children: [
            {
                path: '/',
                roles: [],
                element: lazy(async () => await import('pages/content/HomePage/HomePage')),
            },
            {
                path: '/payment-card-rules',
                roles: [],

                element: lazy(
                    async () =>
                        await import('pages/content/PaymentsCardRulesPage/PaymentsCardRulesPage')
                ),
            },
        ],
    },
    {
        path: '/',
        roles: [],
        header: lazy(() => import('components/template/headers/HeaderOnlyLogo/HeaderOnlyLogo')),
        element: lazy(() => import('components/template/layouts/MainLayout/MainLayout')),
        children: [
            {
                path: 'paymentResult/success',
                roles: [],
                element: lazy(
                    async () =>
                        await import(
                            'pages/content/PaymentResultPage/PaymenResultSuccessPage/PaymentResultSuccessPage'
                        )
                ),
            },
            {
                path: 'paymentResult/fail',
                roles: [],
                element: lazy(
                    async () =>
                        await import(
                            'pages/content/PaymentResultPage/PaymentResultFailPage/PaymentResultFailPage'
                        )
                ),
            },
            {
                path: 'paymentResult/loading',
                roles: [],
                element: lazy(
                    async () =>
                        await import(
                            'pages/content/PaymentResultPage/PaymentResultLoadingPage/PaymentResultLoadingPage'
                        )
                ),
            },
        ],
    },
    {
        path: '/',
        roles: [],
        // header: lazy(() => import('components/template/headers/HeaderLogo/HeaderLogo')),
        element: lazy(() => import('components/template/layouts/MainLayout/MainLayout')),
        children: [
            {
                path: '*',
                roles: [],
                element: lazy(async () => await import('pages/errors/Error404Page/Error404Page')),
            },
        ],
    },
];

export { routes };

import React, { FC } from 'react';
import { LoaderStandardProps } from './LoaderStandardTypes';
import './LoaderStandard.scss';

const LoaderStandardView: FC<LoaderStandardProps> = ({ fullScreen }) => {
    return (
        <div className={`loader-standard ${fullScreen && 'loader-standard__fullscreen'}`}>
            <div className={'loader-standard__loader'}></div>
        </div>
    );
};

export { LoaderStandardView };

// Слайс отображения информационных хинтов ( об ошибках, успехе выполнения и тд.)
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IStatusInfo } from 'types/IStatusInfo';

// Описание данных хранилища
interface InformerState {
    show: boolean;
    status: IStatusInfo;
    text: string;
    timer: number;
}

// Хранилище данных по умолчанию
const initialState: InformerState = {
    show: false,
    status: 'success',
    text: '3',
    timer: 2,
};

// Логика изменения хранилища
export const informerSlice = createSlice({
    name: 'informer',
    initialState,
    reducers: {
        setInfo: {
            reducer: (state, action: PayloadAction<InformerState>) => {
                state.show = action.payload.show;
                state.status = action.payload.status;
                state.text = action.payload.text;
                state.timer = action.payload.timer;
            },
            prepare: (value: InformerState) => ({ payload: value }),
        },
    },
});

// Деструктуризация actionCreators
export const { setInfo } = informerSlice.actions;

export default informerSlice.reducer;

// Получение цветовой темы приложения
import { ITheme } from 'types/ITheme';
const getTheme = () => {
    // Проверяем localStorage
    const theme = localStorage.getItem('theme');
    if (theme) return theme as ITheme;

    // Проверяем цветовую тему ОС пользователя
    const userMedia = window.matchMedia('(prefers-color-scheme: light)');
    if (userMedia.matches) return 'light' as ITheme;

    // Цветовая тема по умолчанию
    return 'dark' as ITheme;
};

export { getTheme };
